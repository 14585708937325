<template>
  <div v-if="Object.keys(view_room).length !== 0" >
    <div class="mb-1">
      <b-modal id="view-room-modal" title="View Room Details" size="lg" hide-footer>
        <div class="modal-content bg-white rounded">
          <table class="table table-sm">
            <thead>
            <tr>
              <th>Name</th>
              <td>{{ view_room.name }}</td>
            </tr>
            <tr>
              <th>Location</th>
              <td>{{ view_room.location }}</td>
            </tr>
            <tr>
              <th>Capacity</th>
              <td>{{ view_room.capacity }}</td>
            </tr>
            <tr>
              <th>Description</th>
              <td>{{ view_room.description }}</td>
            </tr>
            <tr>
              <th>Image</th>
              <td>
                <span class="ant-badge">
                <a :href="view_room.image_url" target="_blank" download>
                   <span class="ant-avatar ant-avatar-lg ant-avatar-square ant-avatar-icon index_avatar_1A3j0">
                        <img :src="view_room.image_url"  width="1em" height="1em">
                   </span>
                </a>
              </span>
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <td>{{ view_room.status == 1 ? 'Active' : 'Inactive' }}</td>
            </tr>
            <tr>
              <th>Refreshment Required</th>
              <td>{{ view_room.refreshment_required == 1 ? 'Yes' : 'No' }}</td>
            </tr>
            <tr>
              <th>Time Slot</th>
              <td>
                <span v-for="(time_slot, index) in  view_room.time_slots" :key="index">
                    {{ time_slot.time_slot}} <br/>
                  </span>
              </td>
            </tr>
            <tr>
              <th>Room Admins</th>
              <td>
                <span v-for="(room_book_user, index) in  view_room.room_book_users" :key="index">
                    {{ room_book_user.name }} <br/>
                  </span>
              </td>
            </tr>
            </thead>
          </table>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
export default {
  name: 'viewRoom',
  props: ['view_room'],
  data() {
    return {}
  },
}
</script>
