<template>
  <div>
    <div class="mb-5">
      <b-modal id="add-update-room-modal" title="Room Add/Update" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
          <span v-for="(err, index) in validation_errors" :key="index">{{ err[0] }} <br/></span>
        </div>
        <div class="modal-content bg-white rounded">
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="name" class="col-form-label col-form-label-sm">Name <span class="custom-required">*</span></label>
              <input type="text" v-model="room.name" v-validate="'required'" id="name" name="name" class="form-control form-control-sm" placeholder="Enter name"/>
              <div class="invalid-feedback">Name is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="location" class="col-form-label col-form-label-sm">Location</label>
              <input type="text" v-model="room.location" id="location" name="location" class="form-control form-control-sm" placeholder="Enter location"/>
              <div class="invalid-feedback">Location is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="capacity" class="col-form-label col-form-label-sm">Capacity</label>
              <input type="number" v-model="room.capacity" v-validate="'required|numeric'"  id="capacity" name="capacity" class="form-control form-control-sm" placeholder="Enter capacity"/>
              <div class="invalid-feedback">Capacity is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="image" class="col-form-label col-form-label-sm">Image <span v-if="this.btnEdit" style="font-weight: 100;">*Previous file <a :href="room.image_url" target="_blank">click here</a></span></label>
              <input type="file" ref="roomImage" v-validate="{ rules: { required: !this.btnEdit ? true : false} }" id="image" name="image" @change="getRoomImage()" accept=".jpg,.jpeg,.png" class="form-control form-control-sm"/>
              <div class="invalid-feedback">Image is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="status" class="col-form-label col-form-label-sm">Status <span class="custom-required">*</span></label>
              <select v-model="room.status" v-validate="{ rules: { required:  true } }" id="status" name="status" class="form-control form-control-sm">
                <option :value="''">Select Status</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
              <div class="invalid-feedback">Status is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="description" class="col-form-label col-form-label-sm">Description</label>
              <textarea v-model="room.description"  id="description" name="description" class="form-control form-control-sm" placeholder="Enter description"> </textarea>
              <div class="invalid-feedback">Description is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="room_book_users" class="col-form-label col-form-label-sm">Admins <span class="custom-required">*</span></label>
              <v-select v-model="room.room_book_users" multiple  :class="errors.first('room_book_users') ? 'borderColor' : ''" name="room_book_users" id="room_book_users" v-validate="'required'" label="name" :options="room_book_users"></v-select>
              <div v-if="errors.first('room_book_users')" class="error">Admins is required</div>
            </div>
            <div class="col-md-6 col-sm-6 col-lg-6">
              <label for="refreshment_required" class="col-form-label col-form-label-sm">Refreshment Required <span class="custom-required">*</span></label>
              <select v-model="room.refreshment_required" name="refreshment_required" id="refreshment_required"  class="form-control form-control-sm">
                <option value="">Select Refreshment Required</option>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
              <div class="invalid-feedback">Refreshment required is required</div>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12 col-sm-12 col-lg-12">
              <label for="time_slots" class="col-form-label col-form-label-sm">Time Slot <span class="custom-required">*</span></label>
              <b-form-tags input-id="tags-basic" v-validate="'required'" v-model="room.time_slots" name="time_slots" placeholder="Enter time slot" :tag-validator="validator" @tag-state="onTagState"></b-form-tags>
              <div class="text-danger" style="margin-top: 0.25rem; font-size: 0.75rem;" v-if="errors.has('time_slots')">Time slot is required</div>
            </div>
          </div>
          <div class="form-row mt-3 mb-3">
            <div class="col-md-12 col-sm-12 col-lg-12">
              <a-button type="primary" v-if="!this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="save">Add</a-button>
              <a-button type="primary" v-if="this.btnEdit" class="text-center mr-2" :loading="loader"  @click.prevent="update">Update</a-button>
              <a-button type="danger" class="text-center" @click="$bvModal.hide('add-update-room-modal')">Cancel</a-button>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import apiClient from '@/services/axios'

export default {
  name: 'addUpdateRoom',
  props: ['room', 'btnEdit'],
  data() {
    return {
      room_book_users: [],
      validation_errors: {},
      show: false,
      loader: false,
      validTags: [],
      invalidTags: [],
      duplicateTags: [],
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    getUsers() {
      apiClient.get('api/users')
        .then(response => {
          const data = response.data
          this.room_book_users = data.users
        })
        .catch(error => {
          console.log(error)
        })
    },
    onTagState(valid, invalid, duplicate) {
      this.validTags = valid
      this.invalidTags = invalid
      this.duplicateTags = duplicate
    },
    validator(tag) {
      return tag.length === 8 || tag.length === 14
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          const formData = new FormData()
          formData.append('name', this.room.name)
          formData.append('location', this.room.location)
          formData.append('capacity', this.room.capacity)
          formData.append('refreshment_required', this.room.refreshment_required)
          formData.append('description', this.room.description)
          formData.append('status', this.room.status)
          formData.append('room_book_users', JSON.stringify(this.room.room_book_users))
          formData.append('time_slots', JSON.stringify(this.room.time_slots))
          formData.append('image', this.image)
          apiClient.post('api/rooms', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } }).then(response => {
            this.loader = false
            this.$notification.success({
              message: response.data.message,
            })
            this.$emit('resetForm')
            this.$refs.roomImage.value = null
            this.$emit('getRooms')
          }).catch(error => {
            this.loader = false
            this.validation_errors = error.response.data.errors
            this.show = true
            this.hide()
          })
        }
      })
    },
    update() {
      this.$validator.validate().then(valid => {
        if (valid) {
          this.loader = true
          const formData = new FormData()
          formData.append('name', this.room.name)
          formData.append('location', this.room.location)
          formData.append('capacity', this.room.capacity)
          formData.append('refreshment_required', this.room.refreshment_required)
          formData.append('description', this.room.description)
          formData.append('status', this.room.status)
          formData.append('room_book_users', JSON.stringify(this.room.room_book_users))
          formData.append('time_slots', JSON.stringify(this.room.time_slots))
          formData.append('image', this.image)
          apiClient.post('api/rooms/' + this.room.id, formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              if (!response.data.error) {
                this.loader = false
                this.$bvModal.hide('add-update-room-modal')
                this.$emit('getRooms')
                this.$notification.success({
                  message: response.data.message,
                })
              } else {
                this.loader = false
                this.$notification.error({
                  message: 'Update Failed',
                })
              }
            })
            .catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    getRoomImage() {
      this.image = this.$refs.roomImage.files[0]
      if (this.image.size > 3072000) {
        this.$notification.error({
          message: 'File size not greater then 3 MB',
        })
        this.image = ''
      }
    },
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
  },
}
</script>

<style>
.error{
  display: block;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.borderColor{
  border: 1px solid red !important;
  border-radius:4px !important;
}
</style>
