<template>
  <div class="card">
    <div class="card-body">
      <div class="table-responsive">
        <a href="javascript: void(0);" class="btn btn-sm btn-success float-right mb-1" v-b-modal.add-update-room-modal @click="$bvModal.show('add-update-room-modal'), resetForm()">
          <i class="fa fa-plus"/> Add Room
        </a>
        <table class="table table-sm table-bordered" id="room_list">
          <thead>
          <tr>
            <th style="width: 5%">SL</th>
            <th>Name</th>
            <th>Image</th>
            <th>Time Slot</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(room, index) in rooms" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ room.name }}</td>
            <td>
              <span class="ant-badge">
                <a :href="room.image_url" target="_blank">
                   <span class="ant-avatar ant-avatar-lg ant-avatar-square ant-avatar-icon index_avatar_1A3j0">
                        <img :src="room.image_url"  width="1em" height="1em">
                   </span>
                </a>
              </span>
            </td>
            <td>
              <span v-for="(time_slot, index) in  room.time_slots" :key="index">
                    {{ time_slot.time_slot}} <br/>
              </span>
            </td>
            <td><b-badge :variant="room.status ? 'success' : 'danger'">{{ room.status ? 'Active' : 'Inactive' }}</b-badge></td>
            <td>
              <a class="btn btn-sm btn-primary mr-1" href="javascript: void(0);" @click.prevent="edit(room.id), $bvModal.show('add-update-room-modal')"><i class="fe fe-edit mr-1"></i>Update</a>
              <a class="btn btn-sm btn-danger mr-1" href="javascript: void(0);" @click.prevent="onDelete(room.id)"><i class="fe fe-trash mr-1"></i>Delete</a>
              <a class="btn btn-sm btn-info" href="javascript: void(0);" @click.prevent="view(room), $bvModal.show('view-room-modal')"><i class="fe fe-info mr-2"></i>View</a>
            </td>
          </tr>
          </tbody>
        </table>
        <a-skeleton active :loading="loading"></a-skeleton>
        <h5 class="text-center text-secondary" v-if="rooms.length === 0">
          <Empty :image="simpleImage"></Empty>
        </h5>
      </div>
    </div>
    <add-update-room
      :room="room"
      :btnEdit="btnEdit"
      @getRooms="getRooms"
      @resetForm="resetForm"
      ref="addUpdateRoom">
    </add-update-room>
    <view-room
      :view_room="view_room">
    </view-room>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'
import apiClient from '@/services/axios'
import addUpdateRoom from '@/views/roomBooking/admin/room/modal/addUpdateRoom'
import viewRoom from '@/views/roomBooking/admin/room/modal/viewRoom'

export default {
  name: 'List',
  components: { Empty, addUpdateRoom, viewRoom },
  data() {
    return {
      rooms: [],
      room: {},
      view_room: {},
      loading: false,
      loader: false,
      flag: false,
      btnEdit: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
  mounted() {
    this.getRooms()
  },
  methods: {
    getRooms() {
      this.flag = true
      this.loading = true
      this.loader = true
      apiClient.get('api/rooms')
        .then(response => {
          this.loading = false
          this.flag = true
          this.loader = false
          this.rooms = response.data.rooms
        })
        .catch(error => {
          console.log(error)
        })
    },
    edit(id) {
      this.room = {}
      this.btnEdit = true
      apiClient.get('api/rooms/' + id + '/edit').then(response => {
        if (!response.data.error) {
          this.room = response.data.room
          this.room.time_slots = response.data.time_slots
        }
      })
    },
    onDelete(roomId) {
      if (confirm('Do you really want to delete?')) {
        apiClient.delete('api/rooms/' + roomId).then(response => {
          if (!response.error) {
            if (response.data.error === true) {
              this.$notification.error({
                message: response.data.message,
              })
            } else {
              this.$notification.success({
                message: response.data.message,
              })
              this.getRooms()
            }
          } else {
            this.$notification.error({
              message: 'Deleted Failed',
            })
          }
        }).catch(error => {
          console.log(error)
        })
      }
    },
    resetForm() {
      this.btnEdit = false
      this.room = {}
    },
    view(room) {
      this.view_room = {}
      this.view_room = room
    },
  },
}
</script>

<style scoped>
  table {
    table-layout:fixed;
  }
  td {
    word-wrap:break-word;
  }
  .item:last-child .comma {
    display: none;
  }
</style>
